import { useHistory } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";

const BUTTON_STYLE = { color: "#1890FF", p: 0 };

function DraftQuotation({
  shipmentDetailData,
}: {
  shipmentDetailData: AdminBidDetail;
}) {
  const history = useHistory();

  const { quotationForwarders, quotationsUser } = shipmentDetailData;

  return (
    <Box pb={2}>
      {quotationForwarders.length > 0 && (
        <Box display={"flex"} flexDirection={"column"}>
          <Typography variant="h6">견적서 초안</Typography>

          <Box display={"flex"} flexDirection={"column"} mt={2} gap={2}>
            {quotationForwarders.map((v, index) => {
              return (
                <Box key={index} display="flex" flexDirection={"column"}>
                  <Typography
                    style={{
                      color: BUTTON_STYLE.color,
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    ‣ 초안{index + 1}
                  </Typography>

                  <Box display="flex" alignItems="center" gap={20} mt={2}>
                    <Box>
                      제출가: &nbsp;
                      <Typography component="span" sx={{ fontWeight: "bold" }}>
                        {toThousandUnitFormat(v.totalPrice)}원
                      </Typography>
                    </Box>

                    <Box>
                      제출일자: &nbsp;
                      <Typography component="span" sx={{ fontWeight: "bold" }}>
                        {formatDate({
                          date: v.createdAt,
                          type: "YY_MM_DD",
                        })}
                      </Typography>
                    </Box>

                    <Box>
                      유효기간: &nbsp;
                      <Typography component="span" sx={{ fontWeight: "bold" }}>
                        {formatDate({
                          date: v.expiredAt,
                          type: "YY_MM_DD",
                        })}
                      </Typography>
                    </Box>

                    <Box>
                      초안{index + 1} 수정: &nbsp;
                      <Button
                        variant="outlined"
                        size="small"
                        sx={BUTTON_STYLE}
                        onClick={() => {
                          history.push(
                            `/bid/change-quotation/${shipmentDetailData.id}/${shipmentDetailData.freightType}`
                          );
                        }}
                      >
                        수정
                      </Button>
                    </Box>

                    {quotationsUser.length === 0 && (
                      <Box>
                        초안{index + 1} 선택: &nbsp;
                        <Button
                          variant="outlined"
                          size="small"
                          sx={BUTTON_STYLE}
                          onClick={() => {
                            history.push(
                              `/bid/confirm-quotation/${shipmentDetailData.id}`
                            );
                          }}
                        >
                          선택
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}

      {quotationForwarders.length === 0 &&
        shipmentDetailData.status === "estimating" && (
          <Button
            variant="contained"
            onClick={() => {
              history.push(
                `/bid/applyBid/${shipmentDetailData.freightType}/${shipmentDetailData.id}`
              );
            }}
          >
            견적 지원
          </Button>
        )}
    </Box>
  );
}

export default DraftQuotation;
